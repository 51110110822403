
.App-header img {
  max-width: 400px; /* or any size you prefer */

}

body {
  font-family: -apple-system, Segoe UI, Ubuntu, Helvetica, Arial;
  background-color: #ffffff;
  color: #353739;
  margin: 0;
  overflow-y: scroll;
}

.App {
  position: relative;
  width: 70%;
  margin: 2em auto;
}



/* Add this to your App.css */

.modal-content img {
  max-width: 100%; /* Makes image responsive */
  height: auto; /* Maintain aspect ratio */
  margin-top: 20px; /* Add some space above the image */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
button {
  color: white;
  background-color: #0092e5;
  border: none;
  border-radius: 5px 5px 5px 5px;
  height: 3.03em;
  font-size: 1.1em;
  font-weight: 600;
  outline: none;
  margin: 5px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this to your App.css */

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%; /* Fixed width */
  overflow: auto; /* Adds scroll for overflow content */
  font-size: 0.6em; /* Smaller text */
}



/* Add this to your App.css */

.saas-category {
  margin-bottom: 20px;
}

.saas-category h3 {
  margin-bottom: 10px;
}

.saas-category label {
  display: block;
  margin-bottom: 5px;
}

.saas-category input[type='checkbox'] {
  margin-right: 10px;
}


/* Styles for the table */
table {
  width: 100%;
  border-collapse: collapse; /* Removes the space between table borders */
  margin-top: 20px; /* Adds some space above the table */
}

th, td {
  text-align: center; /* Centers text in the cells */
  padding: 12px; /* Adds padding inside the cells for a better look */
}

th {
  font-size: 18px; /* Larger font size for headings */
  color: #EF476F; /* Color for the heading text to match the button color */
}

td {
  font-size: 16px; /* Font size for the content cells */
}

/* Removes all the borders from the table, th, and td elements */
table, th, td {
  border: none;
}

/* Adds a background to the th element to differentiate it from the td elements */
th {
  background-color: #f3f3f3; /* A light grey background for the table headers */
}

/* Adds a hover effect on the rows */
tr:hover {
  background-color: #f9f9f9; /* A lighter grey background on hover */
}

/* Additional styles to make the table fit in with the AwesomeButton aesthetics */
tbody tr:nth-child(odd) {
  background-color: #fcfcfc; /* Zebra striping for rows, if desired */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px;
  text-align: center;
  
}

th {
  background-color: #f2f2f2;
}
